$(function() {

 	/* Music Page Players
	========================================================================== */

	var playlists = {};

	// playlists.playlists = Object.assign({}, ...showPlaylists);
	// playlists.songs.push({
	// 	name: playlists.playlists.high_school_musical.songs[0].name,
	// 	album: playlists.playlists.high_school_musical.songs[0].album,
	// 	url: playlists.playlists.high_school_musical.songs[0].url,
	// });

	// var t = playlists.playlists;
	// console.log(t);

	var AmplitudeParams = {
		debug: true,
		"songs": [
			{
				"name": "Wildcat Cheer 4",
				"artist": "",
				"album": "High School Musical",
				"url": "//disneychannelonstage.local:3000/wp-content/uploads/2019/07/01-Wildcat-Cheer.mp3",
				"cover_art_url": ""
			}
		],
		"playlists": Object.assign.apply(null, showPlaylists)
	};

	console.log(AmplitudeParams);

	if( typeof playlists != 'undefined' && typeof Amplitude != 'undefined' ) {
		Amplitude.init( AmplitudeParams );

		// $('.audio-player').on('click', function(event) {
		// 	if(
		// 		$(event.target).hasClass('amplitude-play-pause') ||
		// 		$(event.target).hasClass('amplitude-next') ||
		// 		$(event.target).hasClass('amplitude-prev')
		// 	) return;
		// 	$('.amplitude-play-pause').click();
		// 	if( $('.amplitude-playing') ) {
		// 		Amplitude.pause();
		// 	} else {
		// 		Amplitude.playNow( playlists.playlists.freaky_friday.songs[0] );
		// 	}
		// });

	}

});

jQuery(document).ready(function($) {
	$('.carousel').flickity({
		// options
		cellAlign: 'left',
		contain: true,
		draggable: true,
		wrapAround: true,
		pageDots: false,
		imagesLoaded: true,
		lazyLoad: true
	});

	var isHash = window.location.hash;

	if(isHash){
		$('html, body').animate({ scrollTop:  $(isHash).offset().top }, 1000);
	}
});